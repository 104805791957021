import pageBuilderPlugins from "@webiny/app-page-builder/site/plugins";
import pageBuilderTheme from "./themes/app-page-builder-theme-finwise";
import pageBuilderUseSsrCacheTagsPlugins from "@webiny/app-page-builder/site/plugins/useSsrCacheTags";
import { fileUploadPlugin, imagePlugin } from "@webiny/app/plugins";
import cookiePolicyPlugins from "@webiny/app-cookie-policy/render";
import typeformPlugins from "@webiny/app-typeform/render";
import mailchimpPlugins from "@webiny/app-mailchimp/render";
import gtmPlugins from "@webiny/app-google-tag-manager/render";
import i18nPlugins from "@webiny/app-i18n/site/plugins";
import formsSitePlugins from "@webiny/app-form-builder/site/plugins";
import formsPbPlugins from "@webiny/app-form-builder/page-builder/site/plugins";
import formsTheme from "@webiny/app-form-builder-theme";
import titleGridPageList from "pkg-webiny-plugins/packages/element-pages-list-component-title/dist/render";
import googleMapsPlugin from "pkg-webiny-plugins/packages/app-custom-google-map/dist/render";
import classname from "pkg-webiny-plugins/packages/app-classname/dist/render";
import finWiseCalc from "pkg-webiny-plugins/packages/app-custom-finwise-calc/dist/render";
import branchesMapsPlugin from "pkg-webiny-plugins/packages/app-custom-branches-map/dist/render";

const plugins = [
    fileUploadPlugin(),
    imagePlugin,
    pageBuilderPlugins,
    pageBuilderUseSsrCacheTagsPlugins,
    pageBuilderTheme(),
    cookiePolicyPlugins,
    typeformPlugins,
    mailchimpPlugins,
    gtmPlugins,
    i18nPlugins,
    formsSitePlugins,
    formsPbPlugins,
    formsTheme(),

    // customized
    titleGridPageList,
    googleMapsPlugin,
    classname,
    finWiseCalc,
    branchesMapsPlugin,
];

export default plugins;
